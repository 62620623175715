<template>
 <v-container class="baggy-pages our-values-section" fluid>

		<v-row>
			<v-col>
				<strong><h3>{{ $t('message.ourValues.title') }}</h3></strong>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="our-values-section">
				<strong style="color:#9fcb78;">{{ $t('message.ourValues.safeness.title') }}</strong>
				<p>{{ $t('message.ourValues.safeness.paragraph1') }}</p>
				<p>{{ $t('message.ourValues.safeness.paragraph2') }}</p>
				<p>{{ $t('message.ourValues.safeness.paragraph3') }}</p>
				<p>{{ $t('message.ourValues.safeness.paragraph4') }}</p>
			</v-col>
		</v-row>	
		<v-row>
			<v-col class="our-values-section">
				<strong style="color:#9fcb78;">{{ $t('message.ourValues.affordability.title') }}</strong>
				<p>{{ $t('message.ourValues.affordability.paragraph1') }}</p>
				<p>{{ $t('message.ourValues.affordability.paragraph2') }}</p>
			</v-col>
		</v-row>	
		<v-row>
			<v-col class="our-values-section">
				<strong style="color:#9fcb78;">{{ $t('message.ourValues.reliability.title') }}</strong>
				<p>{{ $t('message.ourValues.reliability.paragraph1') }}</p>
				<p>{{ $t('message.ourValues.reliability.paragraph2') }}</p>
				<p>{{ $t('message.ourValues.reliability.paragraph3') }}</p>
			</v-col>
		</v-row>	
		
	</v-container>
</template>



<style>
	.our-values-section {
		font-weight: bold; 
		line-height: 1.4;
	}

</style>


<script>
  export default {
      name: 'OurValuesSection'
  }
</script>


