<template>
	<div class="faq-section">
		<v-row>
			<v-col>
				<strong>FAQ</strong>
			</v-col>
		</v-row>
		<v-row>
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-title style="font-weight:bold; color:#1c4c34">
						{{ $t('message.FAQ.faq1.title') }}
					</v-expansion-panel-title>
					<v-expansion-panel-text style="color:#1c4c34" >
						<p> {{ $t('message.FAQ.faq1.paragraph1') }} </p>
						<p>{{ $t('message.FAQ.faq1.paragraph2') }}</p>
						<p>{{ $t('message.FAQ.faq1.paragraph3') }}</p>
						<p>{{ $t('message.FAQ.faq1.paragraph4') }}</p>
						<p>{{ $t('message.FAQ.faq1.paragraph5') }} </p>				
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-title style="font-weight:bold; color:#1c4c34">
						{{ $t('message.FAQ.faq2.title') }}
					</v-expansion-panel-title>
					<v-expansion-panel-text style="color:#1c4c34" >
						{{ $t('message.FAQ.faq2.paragraph1') }}
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-title style="font-weight:bold; color:#1c4c34">
						{{ $t('message.FAQ.faq3.title') }}
					</v-expansion-panel-title>
					<v-expansion-panel-text style="color:#1c4c34" >
						{{ $t('message.FAQ.faq3.paragraph1') }}
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-title style="font-weight:bold; color:#1c4c34">
						{{ $t('message.FAQ.faq4.title') }}
					</v-expansion-panel-title>
					<v-expansion-panel-text style="color:#1c4c34" >
						{{ $t('message.FAQ.faq4.paragraph1') }}
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-row>	
	</div>
</template>



<style>
	.faq-section {
		padding: 0px 10px 25px 10px;
	}

</style>


<script>
  export default {
      name: 'FAQSection'
  }
</script>


