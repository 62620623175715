<template>
  <v-container class="baggy-pages how-it-works-section" >
    <section class="v-container v-container--fluid v-locale--is-ltr pa-4 pa-sm-6 pa-md-8">
      <v-row>
        <strong><h3>{{ $t('message.howItWorks.title') }}</h3></strong>
      </v-row>
        &nbsp;
      <v-row>
        <strong>
       {{ $t('message.howItWorks.paragraph1') }}
        </strong>
      </v-row>
      &nbsp;
      <v-row align="center" justify="center">
        <img src="@/assets/grafica-cartello-baggysitter.png" alt="cart"/>
      </v-row>
       &nbsp;
  
      <v-row>
        <strong>
           {{ $t('message.howItWorks.paragraph2') }}
        </strong>
      </v-row>
     
  </section>
  </v-container>
</template>

<script>

export default {
  name: 'HowItWorksView',
  methods: {
    goToHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style>

.baggy-pages {
  color:#1c4c34; 
  margin-top:70px;
}

@media screen and (max-width: 600px) {
  img {
    width:300px;
  }
}


@media screen and (min-width: 600px) {
  img {
    width:500px;
  }
}





</style>