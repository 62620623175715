<template>
    <div>
        <div class="booking-section-row">
            <v-select
                :label="$t('message.booking.nationality')"
                item-title="title"
                item-value="code"
                :items="countriesOptions"
                v-model="nationality"
                :clearable="true"
            >
            </v-select>
        </div>
        <div class="booking-section-row" style="font-size:10px; text-align:left;">
            <p>{{ $t('message.booking.tooltip1') }}</p>
            <p>{{ $t('message.booking.tooltip2') }}</p>
            <v-text-field
                :label="$t('message.booking.bookingNumber')"
                v-model="bookingNumber"
            >
            </v-text-field>
        </div>
        <div class="booking-section-row">
            <v-text-field :label="$t('message.booking.email')" v-model="customer_email">
            </v-text-field>
        </div>
        <div class="booking-section-row">
            <v-text-field
                :label="$t('message.booking.fullName')"
                v-model="customer_name"
            >
            </v-text-field>
        </div>
        <div class="booking-section-row">
            <MazPhoneNumberInput
                v-model="phone"
                style="width: 100%; margin-bottom: 20px;"
                v-model:country-code="countryCode"
                show-code-on-list
                :preferred-countries="['IT', 'FR', 'BE', 'DE', 'US', 'GB']"
                @update="results = $event"
            />
        </div>
        <v-row class="booking-section-row" style="display: flex; flex-direction: row; justify-content: center;">
            <v-col-1>
                <v-checkbox v-model="checkbox"></v-checkbox>
            </v-col-1>
            <v-col>
                <v-dialog max-width="500">
                    <template v-slot:activator="{ props: activatorProps }">
                        <span class="terms-conditions" style="color: #1c4c34;" v-bind="activatorProps" id="terms-coditions-span">  
                        {{$t('message.booking.terms') }}</span>
                    </template>
                    <template v-slot:default="{ isActive }">
                        <v-card max-width="500">
                            <template v-slot:title>
                                <div style="width: 100%; display: flex; flex-direction: row; justify-content:space-between; margin-bottom: 30px;">
                                    <span>{{ $t('message.booking.termsTitle') }}</span>
                                    <v-icon @click="isActive.value = false" class="close-button">mdi-close</v-icon>
                                </div>
                            </template>
                            <v-card-text>
                                <CondizioniServizioView></CondizioniServizioView>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                text="CLOSE"
                                @click="isActive.value = false"
                                ></v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-col>
        </v-row>
        <div
            v-if="fullPrice && discountedPrice"
            class="booking-section-row price-preview"
        >
            <span style="color: red; text-decoration: line-through;">{{ fullPrice }} &#8364;</span>
            <span>{{ discountedPrice }} &#8364;</span>
        </div>
        <v-btn class="baggy-button"
            @click="checkout"
            elevation="2"
            text color="#1c4c34"
            :loading="loading"
            :disabled="!mandatoryFieldsFilled"
        >
            {{ $t('message.booking.proceed') }}
        </v-btn>
    </div>
</template>

<script>
import { allCountries } from 'country-region-data';
import CondizioniServizioView from '../views/CondizioniServizioView.vue'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

export default {
    name: 'BookingSectionV2',
    props: {
        loading: Boolean,
        discountedPrice: Number,
        fullPrice: Number
    },
    components: {
        CondizioniServizioView,
        MazPhoneNumberInput
    },
    computed: {
        mandatoryFieldsFilled () {
            var validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            var validPhoneRegex = /[2-9]{1}\d{2}/;
            var validCustomerName = /[A-Za-z]/;

            return (
                (this.customer_email !== null) &&
                ( this.customer_email.match(validEmailRegex)) &&
                (this.customer_name !== null) &&
                ( this.customer_name.match(validCustomerName)) &&
                (this.checkbox) &&
                (this.phone !== null) && 
               (this.phone.match(validPhoneRegex))
            )
        }
    },
    data () {
        return {
            countriesOptions: [],
            nationality: null,
            customer_email: null,
            customer_name: null,
            phone: null,
            checkbox: false,
            bookingNumber: null
        }
    },
    mounted () {
        this.countriesOptions = allCountries.map((item) => {
            return {
                title: item[0],
                code: item[1]
            }
        })
    },
    methods: {
        checkout () {
            const payload = {
                nationality: this.nationality,
                bookingNumber: this.bookingNumber,
                customer_email: this.customer_email,
                customer_name: this.customer_name,
                phone: this.phone
            }
            this.$emit('secondStep', payload)
        }
    }
}
</script>

<style scoped>
.baggy-button {
    color: #1c4c34;
    width:100%;
}

.price-preview {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}

.price-preview span {
    font-size: 30px;
}
</style>