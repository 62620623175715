<template>
    <v-container class="baggy-pages" fluid>
      <v-row>
        <v-col>
        <v-banner class="v-banner-resize">
          <v-row-4>
            <v-col>
              <img src="@/assets/discount.png" style="width:50px;" alt="discount"/>
            </v-col>
          </v-row-4>
          <v-row-4>
            <v-col style="color:#1c4c34;" class="baggy-discount">
              <strong>{{ $t('message.home.discountBanner') }}</strong>
            </v-col>
          </v-row-4>
          <v-row-4>
            <v-col>
              <img src="@/assets/discount.png" style="width:50px;" alt="discount"/>
            </v-col>
          </v-row-4>
        </v-banner>
        </v-col>
      </v-row>
      <v-row>
        <v-col no-gutters >
            <v-container>
              <v-row>
                <IntroSection></IntroSection>
              </v-row>
                   &nbsp;
               <a id="our-values"/>
              <v-row class="our-values">
                <OurValuesSection>
                </OurValuesSection>
              </v-row>
            </v-container>
        </v-col>

        <v-col no-gutters lg="4" md="4" sm="12">
          <div id="book">
            <BookingSection>
            </BookingSection>
          </div>
        </v-col>
      </v-row>   

      <div class="baggy-bottom-section">
        <v-row class="how-it-works">
          <a id="how-it-works"/>
          <HowItWorksSection>
          </HowItWorksSection>
        </v-row>   

        <v-row class="contact-us">
           <a id="contact-us"/>
            <ContactUsSection>
            </ContactUsSection>
        </v-row>
        <v-row class="become-partner">
           <a id="become-partner"/>
            <BecomePartnerSection>
            </BecomePartnerSection>
        </v-row>
       </div>
      <v-row class="faq">
         <a id="faq"/>
        <v-col no-gutters lg="12" md="12" sm="12">
          <FAQSection>
          </FAQSection>
        </v-col>
      </v-row>
      <LocationSection></LocationSection>
    </v-container>
</template>

<script>
import IntroSection from '@/components/IntroSection'
import BookingSection from '@/components/BookingSection'
import LocationSection from '@/components/LocationSection'
import OurValuesSection from '@/components/OurValuesSection'
import HowItWorksSection from '@/components/HowItWorksSection'
import ContactUsSection from '@/components/ContactUsSection'
import BecomePartnerSection from '@/components/BecomePartnerSection'
import FAQSection from '@/components/FAQSection'

export default {
  name: 'HomeView',
  components: {
    IntroSection,
    BookingSection,
    LocationSection,
    OurValuesSection,
    HowItWorksSection,
    ContactUsSection,
    BecomePartnerSection,
    FAQSection
  },
  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      console.log(top);
      window.scrollTo(0, top);
    }
  }
}

  



</script>

<style scoped>
.baggy-pages {
  color:#1c4c34; 
  margin:0px;
}

.baggy-button {
    color: #1c4c34;
    width:100%;
}

@media screen and (max-width: 600px) {
  .baggy-discount {
    font-size:15px;
  }
}

#logo-img {
  width: 100px;
}

.left-section {
  color: #1c4c34;
}




 .v-banner {
    position: static;
    font-size:20px;
    padding:0;
  }


  @media screen and (max-width: 600px) {
  .baggy-bottom-section {
    padding: 15px;
  }
}

@media screen and (min-width: 480px) {
  .v-banner-resize {
     padding-left:200px;
  }  
}

@media screen and (min-width: 600px) {
  .v-banner-resize {
     padding-left:200px;
  }  
}

.faq {
  justify-content: left;
  background-color:  #f2f2f2;
  margin: 0px;
  overflow-y: scroll;
}

.left-section .bg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    --background: url('~@/assets/bagagli.jpg');
    background-size: cover;
    opacity: 0.2;
    width: 100%;
    height: 100%;
}

.left-section p {
    text-align: left;
}
</style>
