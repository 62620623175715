<template>
  <v-container class="baggy-pages" >
    <section class="v-container v-container--fluid v-locale--is-ltr pa-4 pa-sm-6 pa-md-8">
      <v-row  >
       <strong><h3>{{ $t('message.becomePartner.title') }}</h3></strong>
      </v-row>
           &nbsp;
    <v-row >
        <strong>{{ $t('message.becomePartner.paragraph1') }}</strong>
    </v-row>
         &nbsp;
    <v-row >
        <strong style="color:#9fcb78;">{{ $t('message.becomePartner.paragraph2') }}</strong>
    </v-row>
         &nbsp;
    <v-row>
      <strong> {{ $t('message.becomePartner.paragraph3') }} <span style="color:#9fcb78;"> infocontact@baggysitter.com</span></strong>
    </v-row>
    </section>
  </v-container>
</template>

<script>

export default {
  name: 'BecomePartnerView',
  methods: {
    goToHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style>

 

</style>