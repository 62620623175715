<template>
    <div class="booking-section-v1">
        <div class="booking-section-row">
            <v-select
                :label="$t('message.booking.pickupLocation')"
                :items="pickupLocationOptions"
                v-model="pickupLocation"
                :clearable="true"
            ></v-select> 
        </div>
        <div
            v-if="pickupLocation == $t('message.booking.otherDestination')"
            class="booking-section-row"
        >
            <v-text-field
                :label="$t('message.booking.otherPickupDestination')"
                v-model="customPickupLocation"
            >
            </v-text-field>
        </div>



        <div
            class="booking-section-row"
        >
            <v-select
                :label="$t('message.booking.dropoffLocation')"
                :items="dropoffLocationOptions"
                v-model="dropoffLocation"
                :clearable="true"
            ></v-select>
        </div>
        <div
            v-if="dropoffLocation == $t('message.booking.otherDestination')"
            class="booking-section-row"
        >
            <v-text-field
                label="Other dropoff location (specify address)"
                v-model="customDropoffLocation"
            >
            </v-text-field>
        </div>
        <v-row>

         <v-col >
            <v-dialog>
                <template v-slot:activator="{ props: activatorProps }">
                    <span   v-bind="activatorProps" >
                      <img src="@/assets/info.png" style="width:25px;"/>
                    </span>
                </template>
                <template v-slot:default="{ isActive }">
                    <v-card max-width="500">
                        <template v-slot:title>
                            <div style="width: 100%; display: flex; flex-direction: row; justify-content:space-between; margin-bottom: 30px;">
                                <span>{{ $t('message.booking.tooltipTitlePickupTime') }}</span>
                                <v-icon @click="isActive.value = false" class="close-button">mdi-close</v-icon>
                            </div>
                        </template>
                        <v-card-text>
                                {{ $t('message.booking.tooltipPickupTime') }}
                        </v-card-text>

                    </v-card>
                </template>
            </v-dialog>
        </v-col>

         <v-col >
            <v-dialog>
                <template v-slot:activator="{ props: activatorProps }">
                    <span   v-bind="activatorProps" >
                      <img src="@/assets/info.png" style="width:25px;"/>
                    </span>
                </template>
                <template v-slot:default="{ isActive }">
                    <v-card max-width="500">
                        <template v-slot:title>
                            <div >
                                <span>{{ $t('message.booking.tooltipTitleDropoffTime') }}</span>
                                <v-icon @click="isActive.value = false" class="close-button">mdi-close</v-icon>
                            </div>
                        </template>
                        <v-card-text>
                                {{ $t('message.booking.tooltipDropoffTime') }}
                        </v-card-text>

                    </v-card>
                </template>
            </v-dialog>
        </v-col>

        </v-row>

       

        <div class="booking-section-row date-times">
            <v-btn  class="date-button" variant="outlined" @click="pickupDatetimeDialog=true">
                <span v-if="pickupDateTime">{{ pickupDateTimeFormatted }}</span>
                <span v-else>{{ $t('message.booking.selectPickupTime') }}</span>
            </v-btn>
            <v-btn class="date-button"
                :disabled="pickupDateTime === null"
                variant="outlined"
                @click="dropoffDatetimeDialog=true"
            >
                <span v-if="dropoffDateTime">{{ dropoffDateTimeFormatted }}</span>
                <span v-else>{{ $t('message.booking.selectDropoffTime') }}</span>
            </v-btn>
        </div>
        <div class="booking-section-row">
            <span>{{ $t('message.booking.smallBags') }}</span>
            <v-number-input control-variant="split" :min="0" :max="10" v-model="smallBags">
            </v-number-input>
        </div>
        <div class="booking-section-row">
            <span>{{ $t('message.booking.largeBags') }}</span>
            <v-number-input control-variant="split" :min="0" :max="10" v-model="largeBags">
            </v-number-input>
        </div>
        <div class="booking-section-row">
            <span>{{ $t('message.booking.oofBags') }}</span>
            <v-number-input control-variant="split" :min="0" :max="10" v-model="outOfFormatBags">
            </v-number-input>
        </div>
        <DateTimeSelection
            v-model="pickupDatetimeDialog"
            startHour="08:00"
            endHour="12:00"
            :intervalMinutes="30"
            @picked="pickedDatetime"
        >
        </DateTimeSelection>
        <DateTimeSelection
            v-model="dropoffDatetimeDialog"
            :startDate="pickupDateTime"
            :endDate="pickupDateTime"
            startHour="14:00"
            endHour="19:30"
            :intervalMinutes="30"
            @picked="droppedDatetime"
        >
        </DateTimeSelection>
        <div
            v-if="fullPrice && discountedPrice"
            class="booking-section-row price-preview"
        >
            <span style="color: red; text-decoration: line-through;">{{ fullPrice }} &#8364;</span>
            <span>{{ discountedPrice }} &#8364;</span>
        </div>
       
    </div>
     <v-btn class="baggy-button"
            @click="moveToNextSection"
            elevation="2"
            text color="#1c4c34"
            :disabled="!mandatoryFieldsFilled"
        >
             {{ $t('message.booking.next') }}
        </v-btn>
</template>

<script>
import { VNumberInput } from 'vuetify/labs/VNumberInput'
import DateTimeSelection from './DateTimeSelection.vue'

export default {
    name: 'BookingSectionV1',
    components: {
        DateTimeSelection,
        VNumberInput
    },
    data () {
        return {
            hotelsOptions: ['Hotel NHow Milano', 'Domina Hotel Milano Fiera'],
            airportOptions: ['Milan Linate airport', 'Milan Malpensa airport'],
            pickupLocation: null,
            customPickupLocation: null,
            dropoffLocation: null,
            customDropoffLocation: null,
            pickupDateTime: null,
            dropoffDateTime: null,
            pickupDatetimeDialog: false,
            dropoffDatetimeDialog: false,
            dt_now: null,
            dropoff_start_hour: '08:00',
            smallBags: 0,
            largeBags: 0,
            outOfFormatBags: 0,
            fullPrice: null,
            discountedPrice: null
        }
    },
    watch: {
        smallBags() {
            this.getPricePreview()
        },
        largeBags () {
            this.getPricePreview()
        },
        outOfFormatBags () {
            this.getPricePreview()
        }
    },
    computed: {
        pickupLocationOptions () {
            if(this.dropoffLocation === null) {
                return this.hotelsOptions.concat(this.airportOptions).concat([this.$t('message.booking.otherDestination')])
            }
            else {
                if(this.hotelsOptions.includes(this.dropoffLocation)) {
                    return this.airportOptions
                }
                else if (this.airportOptions.includes(this.dropoffLocation)) {
                    return this.hotelsOptions.concat([this.$t('message.booking.otherDestination')])
                } else {
                    return this.airportOptions
                }
            }
        },
        dropoffLocationOptions () {
            if(this.pickupLocation === null)
                return this.hotelsOptions.concat(this.airportOptions).concat([this.$t('message.booking.otherDestination')])
            else {
                if(this.hotelsOptions.includes(this.pickupLocation)) {
                    return this.airportOptions
                }
                else if (this.airportOptions.includes(this.pickupLocation)) {
                    return this.hotelsOptions.concat([this.$t('message.booking.otherDestination')])
                } else {
                    return this.airportOptions
                }
            }
        },
        pickupDateTimeFormatted () {
            if(this.pickupDateTime)
                return this.pickupDateTime.toLocaleString()
            
            return ''
        },
        dropoffDateTimeFormatted () {
            if(this.dropoffDateTime)
                return this.dropoffDateTime.toLocaleString()

            return ''
        },
        mandatoryFieldsFilled () {
            return (
                this.pickupLocation !== null &&
                this.dropoffLocation !== null &&
                this.pickupDateTime !== null &&
                this.dropoffDateTime !== null &&
                (this.smallBags > 0 || this.largeBags > 0 || this.outOfFormatBags > 0)
            )
        }
    },
    methods: {
        pickedDatetime (chosenDate) {
            this.pickupDateTime = chosenDate
            this.pickupDatetimeDialog = false
        }, 
        droppedDatetime (chosenDate) {
            this.dropoffDateTime = chosenDate
            this.dropoffDatetimeDialog = false
        },
        moveToNextSection () {
            const payload = {
                pickupLocation: this.customPickupLocation === null ? this.pickupLocation : this.customPickupLocation,
                dropoffLocation: this.customDropoffLocation === null ? this.dropoffLocation : this.customDropoffLocation,
                smallBags: this.smallBags,
                largeBags: this.largeBags,
                outOfFormatBags: this.outOfFormatBags,
                pickupTimestamp: this.pickupDateTime.toFormat("yyyy-LL-dd'T'HH:mm'Z'"),
                dropoffTimestamp: this.dropoffDateTime.toFormat("yyyy-LL-dd'T'HH:mm'Z'")
            }
            this.$emit('firstStep', {
                payload: payload,
                fullPrice: this.fullPrice,
                discountedPrice: this.discountedPrice
            })
        },
        getPricePreview () {
            this.axios.post(process.env.VUE_APP_API_URL + '/cost-preview', {
                smallBags: this.smallBags,
                largeBags: this.largeBags,
                outOfFormatBags: this.outOfFormatBags
            }).then((resp) => {
                this.fullPrice = resp.data['full_price']
                this.discountedPrice = resp.data['discounted_price']
            })
        }
    }
}
</script>

<style scoped>

.baggy-button {
    color: #1c4c34;
    width:100%;
}

h3 {
    margin: 40px 0 0;
}

.booking-section {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #1c4c34;
    background-color: white;
    height: 100%;
}

.booking-section .booking-section-row {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

.booking-section .date-times{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 480px) {
    .booking-section .date-times .date-button{
        width: 150px;
        font-size: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.booking-section .date-time-section {
    display: flex;
    flex-direction: column;
}

.booking-section .date-time-section .button-section {
    margin-top: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.booking-section .date-time-section .selection-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.booking-section .date-time-section p {
    text-align: left;
    margin-left: 20px;
}

.booking-section .date-time-section .btn-box {
    flex-grow: 1;
}

@media screen and (max-width: 600px) {
    .terms-conditions {
        font-size:12px;
    }
}

#terms-coditions-span:hover {
    cursor: pointer;
}

.price-preview {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}

.price-preview span {
    font-size: 30px;
}
</style>


