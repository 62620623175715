<template>
    <div class="booking-section">
        <h3>{{ $t('message.booking.title') }}</h3>
        <BookingSectionV1
            v-if="step === 1"
            @firstStep="parseFirstStep"
        ></BookingSectionV1>
        <BookingSectionV2
            v-if="step === 2"
            :loading="loading"
            :discountedPrice="discountedPrice"
            :fullPrice="fullPrice"
            @secondStep="parseSecondStep">
        </BookingSectionV2>
    </div>
</template>

<script>
import BookingSectionV1 from './BookingSectionV1.vue'
import BookingSectionV2 from './BookingSectionV2.vue'

export default {
    name: 'BookingSection',
    components: {
        BookingSectionV1,
        BookingSectionV2
    },    
    data () {
        return {
            createOrderPayload: {},
            step: 1,
            loading: false
        }
    },
    methods: {
        checkout () {
            this.loading = true
            this.axios.post(process.env.VUE_APP_API_URL + '/create-order', this.createOrderPayload).then((response) => {
                window.location.href = response.data.Location
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        parseFirstStep (payload) {
            this.createOrderPayload = {
                ...payload.payload
            }
            this.fullPrice = payload.fullPrice
            this.discountedPrice = payload.discountedPrice
            this.step = 2
        },
        parseSecondStep (payload) {
            this.createOrderPayload = {
                ...this.createOrderPayload,
                ...payload
            }
            this.loading = true
            this.checkout()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.baggy-button {
    color: #1c4c34;
    width:100%;
}

h3 {
    margin: 40px 0 0;
}

.booking-section {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #1c4c34;
    background-color: white;
    height: 100%;
}

.booking-section .booking-section-row {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

.booking-section .date-times{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


@media screen and (max-width: 480px) {
    .booking-section .date-times .date-button{
        width: 150px;
        font-size: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}



.booking-section .date-time-section {
    display: flex;
    flex-direction: column;
}

.booking-section .date-time-section .button-section {
    margin-top: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.booking-section .date-time-section .selection-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.booking-section .date-time-section p {
    text-align: left;
    margin-left: 20px;
}

.booking-section .date-time-section .btn-box {
    flex-grow: 1;
}


@media screen and (max-width: 600px) {
    .terms-conditions {
        font-size:12px;
    }
}


#terms-coditions-span:hover {
    cursor: pointer;
}
</style>