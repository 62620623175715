<template>
  <v-layout class="layout" id="app" theme="light">
    <NavigationSection></NavigationSection>
    <v-main h-auto>
      <router-view/>
    </v-main>
    <FooterSection>
    </FooterSection>
  </v-layout>
</template>

<script>
import FooterSection from '@/components/FooterSection'
import NavigationSection from '@/components/NavigationSection'

export default {
  name: 'App',
  components: {
    FooterSection,
    NavigationSection
  }
}
</script>

<style>

html {
  height: 100%;
}

body {
  background-color: white;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


#app-bar {
  padding-left: 0px;
  padding-right: 50px;
}

#logo-img {
  width: 100px;
}

#footer {
  min-height: 100px;
}
</style>
