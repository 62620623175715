<template>
    <div class="about">
      <h1>Request sent successfully</h1>
        <p>We'll let you know by email if your request can be completed and the link for the payment.</p>
        <p>Please check your spam folder in case you can't find the email in your inbox.</p>
        <v-btn color="primary" @click="goToHome">
          Back to home
        </v-btn>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'SentRequestView',
    methods: {
      goToHome () {
        this.$router.push('/')
      }
    }
  }
  </script>
  
  <style scoped>
  .about {
    text-align: center;
    margin-top: 50px;
  }
  
  h1 {
    color: white;
  }

  p {
    color: white;
    margin-bottom: 20px;
  }
  </style>