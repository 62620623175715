<template>
	<div>
		<v-app-bar prominent>
			<RouterLink to="/">
				<img id="logo-img" src="@/assets/logo-def.jpg" alt="logo"/>
			</RouterLink>
			<v-spacer></v-spacer>
			<div class="languages">
				<img
					v-if="$i18n.locale === 'it'"
					@click="changeLocale('en')"
					class="flag"
					src="@/assets/icons/uk-flag.svg"
					alt="uk-flag"
				/>
				<img
					v-if="$i18n.locale === 'en'"
					@click="changeLocale('it')"
					class="flag"
					src="@/assets/icons/italy-flag.svg"
					alt="it-flag"
				/>
			</div>
			<v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" location="right">
				<v-list >
					<v-container class="baggy-menu">
					<v-row>
						<v-col>
							<strong><a @click="goto('our-values')">{{ $t('message.menu.ourValues') }}</a></strong>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<strong><a @click="goto('how-it-works')">{{ $t('message.menu.howItWorks') }}</a></strong>
						</v-col>
					</v-row>
					<!--
					<v-row>
						<v-col>
							<strong><a @click="goto('about-us')">{{ $t('message.menu.aboutUs') }}</a></strong>
						</v-col>
					</v-row>
					-->
					<v-row>
						<v-col>
							<strong><a @click="goto('contact-us')">{{ $t('message.menu.contactUs') }}</a></strong>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<strong><a @click="goto('become-partner')">{{ $t('message.menu.becomePartner') }}</a></strong>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<strong><a @click="goto('faq')">{{ $t('message.menu.faq') }}</a></strong>
						</v-col>
					</v-row>
					
					</v-container>
				</v-list>
			</v-navigation-drawer>
		</div>
				
</template>



<style>
a:hover {
	cursor: pointer;
}

.baggy-menu {
	color: #1c4c34;
}

.languages {
	margin-top:10px;

}

.flag {
	width: 30px;
}

.flag:hover {
	cursor: pointer;
}

</style>


<script>
 export default {
    data: () => ({
      drawer: false,
      group: null
    }),

    watch: {
      group () {
        this.drawer = false
      },
    },
   methods: {
    goto(refName) {
        var element = document.getElementById(refName).offsetTop-80;
        console.log(element);
        window.scrollTo(0, element);
        this.drawer=false;

    },
    changeLocale(locale) {
		console.log('changing locale to: ', locale)
		this.$i18n.locale = locale
	}
  }
}
</script>


