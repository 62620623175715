<template>
  <div class="condizioni-servizio" style="color:black;">
  
<p><strong>1. PREMISES</strong></p>
<p>1.1. The terms and conditions indicated below (“Service Conditions”)
are drawn up by Lightravel S.r.l., with headquarters in Milan, via
Valtellina 56, VAT number 13535960960, certified email address
<a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="85e9ece2edf1f7e4f3e0e9c5f5e0e6ece8f5f7e0f6e0abecf1">[email&#160;protected]</a> (“Lightravel”).</p>
<p>The Conditions of Service contain the regulations regarding the use
of the services offered by Lightravel under the “Baggysitter” brand and
access to the website www.baggysitter.com (“Site”) which allows users to
access and book the services offered by Lightravel (“Users”).</p>
<p>1.2. Please read these terms and conditions carefully before
accepting them.</p>
<p>1.3. The use of the services offered by Lightravel entails the
automatic acceptance by the Users of these Conditions of Service which
govern the use of the same and must be, necessarily and explicitly,
accepted in advance.</p>
<p>1.4. Lightravel may modify these Conditions of Service periodically,
if this is justified in order to comply with emerging legislative needs,
implement organizational changes aimed at improving the service or
prevent the recurrence of incorrect conduct by users when these have not
already been regulated by these Conditions of the Service or in respect
of which the discipline already envisaged has proven ineffective for
their prevention.</p>
<p><strong>2. DESCRIPTION OF SERVICES</strong></p>
<p>2.1. Lightravel offers, through its Site, a Luggage transport and
storage service (as defined below) with collection and relative delivery
or delivery to the place indicated by the User ("Service").</p>
<p><strong>3. PURPOSE OF THE DEPOSIT</strong></p>
<p>3.1. The User may deliver and deposit to Lightravel luggage and/or
any packages and/or parcels (hereinafter "Baggage" or "Baggage") for
which there are no transport prohibitions and in compliance with the
provisions regarding the content permitted therein , as regulated in the
following points of the Conditions of Service.</p>
<p>3.2. Baggage must respect the limits and exclusions referred to in
the art. 16.</p>
<p><strong>1. USER STATUS</strong></p>
<p>1.1. By registering with Baggysitter (by Lightravel), whether via the
Site or Application, the User expressly declares:</p>
<p>- to be of age;</p>
<p>- to have the capacity to act and to be able to conclude legally
binding contracts;</p>
<p>- to have read, understood and accepted the Conditions of Service, as
well as to have read, understood and accepted the conditions relating to
privacy legislation and the processing of personal data, which can be
found on the conditions page.</p>
<p>1.2. Users are solely responsible for their own connections and
equipment, including the costs inherent therein, necessary for access to
the Internet, as well as for the use of the website and/or application
and the Service.</p>
<p><strong>2. RESERVATION</strong></p>
<p>2.1. Booking constitutes the process by which the User, via the Site
or Application, sends a request to Lightravel detailing how to use the
Service. The booking process takes place according to the process
indicated below.</p>
<p>2.2. The user indicates the date on which he intends to use the
Service.</p>
<p>2.3. The User enters the place where he intends the Luggage to be
collected by indicating the address of interest among those possible in
the drop-down menu.</p>
<p>2.4. To proceed with the booking it is necessary to enter the data
relating to the number of bags to be entrusted to the Service and their
dimensions.</p>
<p>2.5. Subsequently, after having also defined the place of delivery
and the number of Luggage, the User proceeds to check the availability
of the Service for the day of interest (in case of collection at a
specific address, the user also chooses the time slot for collection
among those automatically indicated by the Site or Application) and to
book the slot.</p>
<p><strong>3. PAYMENT METHODS AND RATES</strong></p>
<p>3.1. Once the registration and booking procedures for the Service
have been completed, in order to finalize the purchase it will be
necessary to indicate a payment method among those provided (by way of
example but not limited to, payments can be made by credit card). In the
case of payment by credit card, the User must enter the card details (if
not already entered previously) and indicate whether an invoice is
required. In the case of an invoice request, the User must enter all the
data necessary for correct invoicing.</p>
<p>3.2. The rates foreseen for the use of the Service are as
follows:</p>
<p>- Small: Cabin luggage (maximum dimensions 55x40x20 cm, 10kg max):
€20.99 for the first piece of luggage; + €7.5 the second in baggage;
from the third bag + €5 each</p>
<p>- Large: Hold luggage (maximum dimensions 85 x 55 x 35 cm, 35kg max):
€25.99 for the first piece of luggage; + €9.5 from the second bag; from
the third bag + €7 each</p>
<p>- OoF: Oversized Baggage (OoF) (200 x 100 x 60 cm, 35kg max): €35.99
for the first Baggage; for subsequent OoF Baggage + €20 each</p>
<p>- Mix of Baggage: or At least 1 Large + X Small/Large: Price of a
first Large Bag + Price of a second Small/Large Bag + Price of a
third/fourth Small/Large Bag</p>
<p>or 1 Out of Format + X Small/Large: Price 1 OoF + Price of a second
Small/Large Bag + Price of a third/fourth Small/Large Bag</p>
<p><strong>1. CONFIRMATION OF THE RESERVATION</strong></p>
<p>1.1. The service is considered booked only once the payment has been
correctly made and the booking confirmation email has been received at
the email address connected to the User Profile ("Booking
Confirmation").</p>
<p>1.2. Upon Booking Confirmation the user will receive an email with a
unique service number [order ID].</p>
<p>1.3. The Booking Confirmation received via email address contains the
summary of the booking which indicates the main information relating to
the booking (number of Baggage, place and date of delivery, booked time
slot).</p>
<p><strong>2. LUGGAGE DELIVERY METHODS</strong></p>
<p>2.1. Once the entire booking procedure has been completed correctly
and the Booking Confirmation has been received via email,</p>
<p>- in the IN phase, i.e. requesting the collection service from an
entry point into the city and arrival point at the accommodation
facility:</p>
<p>a) for customers staying in Affiliated Hotels:</p>
<p>i) the customer will be able to leave their luggage at the deposit
located at the entry point into the city;</p>
<p>ii) the warehouse staff will tag the suitcases with an adhesive tag
that identifies the baggage and a security tag;</p>
<p>iii) the luggage will then be delivered to the hotel reception.</p>
<p>b) for customers staying in an affiliated B&amp;B:</p>
<p>i) the customer will be able to leave their luggage at the deposit
located at the entry point into the city;</p>
<p>ii) the warehouse staff will tag the suitcases with an adhesive tag
that identifies the baggage and a security tag;</p>
<p>iii) the customer is required to present himself at the delivery
point at least 10 minutes before the established time and wait for the
Lighttravel employee; the driver will contact the customer 30 minutes
before delivery;</p>
<p>iv) upon delivery of the Baggage, the Lightravel employee hand
delivers the baggage to the customer who shows the unique service number
and other booking data in order to compare them with the adhesive band
on the baggage itself;</p>
<p>v) the driver is required to wait for the customer's arrival no later
than 10 minutes from the delivery time.</p>
<p>- in the OUT phase, i.e. requesting the collection service from an
accommodation facility and delivery service at the exit point from the
city:</p>
<p>a) for customers staying in Affiliated Hotels:</p>
<p>i) the customer can leave their luggage at reception;</p>
<p>ii) at the reception the user will be given an adhesive band to
uniquely label the Baggage;</p>
<p>iii) upon collection of the Baggage, the Lightravel employee affixes
a security band and notes the unique booking code present on the
suitcase.</p>
<p>b) for customers staying in an affiliated B&amp;B:</p>
<p>i) the customer is required to present himself at the collection
point at least 10 minutes before the established time and wait for the
Lighttravel employee; the driver will contact the customer 30 minutes
before delivery;</p>
<p>ii) upon collection of the Baggage, the Lightravel employee confirms
receipt of the Baggage by transcribing/scanning the unique service
number and applies a specific security band and one for baggage
recognition, linking it to the user's profile;</p>
<p>iii) the driver is required to wait for the customer's arrival within
10 minutes of the delivery time.</p>
<p>9.2. In any case, the Lightravel employee will also, in the event of
the presence of obvious damage to the Baggage(s), document it through
photographic evidence.</p>
<p><strong>1. WITHDRAWAL</strong></p>
<p>1.1. The collection of the entrusted Baggage takes place at the
pre-established storage point and specifically indicated at the time of
booking.</p>
<p>1.2. If at an airport depot, the collection time must take place
between 2.00 pm and no later than 7.30 pm</p>
<p>1.3. Lightravel is never responsible in the event of loss of flights
or trains, goodwill, earnings, market profits, reputation, customers,
use, opportunities, nor in any case for any indirect, accidental damage
or loss.</p>
<p>1.4. Upon collection, the user is obliged to verify the integrity of
the seal affixed at the time of delivery and report any damage and/or
tampering.</p>
<p><strong>2. COMPLIANCE OF THE BAGGAGE AND STATUS OF THE
SAME</strong></p>
<p>2.1. Upon delivery of the Baggage to the employee, the latter
verifies that the dimensions comply with what was declared and booked by
the User.</p>
<p>2.2. In the event of discrepancies in dimensions, the employee
contacts the Lightravel Assistance number and proceeds to charge the
user a penalty in accordance with the price difference between the price
paid and the actual price as defined in paragraph 7.2 plus a penalty of
additional 15 euros and/or to refuse delivery where the dimensions
exceed the space available for transport.</p>
<p>2.3. To guarantee the good condition of the Baggage at the time of
collection, the employee present at the time of delivery is authorized
to scan it using photographs in the event of obvious damage.</p>
<p>2.4. These scans using photographs will also be useful to identify,
at the time of collection, any damage that may arise from the transport
and transfer of the same and will be kept for 7 (seven) days, equal to
the time needed for the User to be able to activate the complaint
procedure for damage.</p>
<p><strong>3. TRANSPORT AND STORAGE CONTRACT AT THE
WAREHOUSES</strong></p>
<p>3.1. Although not expressly provided for in these Conditions of
Service, in relation to transport and storage at the Lightravel service
depots, the provisions of the law of the Italian State apply exclusively
and, specifically, the legislation relating to the transport and storage
contract. storage of goods.</p>
<p>3.2. It is understood that</p>
<p>- the rules of the transport contract apply during the phase in which
the Luggage is transferred to the place of storage at the Lightravel
service depots:</p>
<p>- the regulations of the storage contract apply when the Baggage
arrives at the Ligtravel Deposits and is stored until it is collected by
the User. Lightravel Warehouses means warehouses and/or other delivery
points managed by Lightravel directly or indirectly through
sub-warehouses and/or other suppliers. The User acknowledges that,
according to the Conditions of Service, Lightravel may sub-deposit the
Baggage collected, without prejudice to its responsibility towards the
User.</p>
<p><strong>1. LUGGAGE CONTENTS AND GUARANTEES: PROHIBITED
ITEMS</strong></p>
<p>1.1. The User guarantees that the content of the Luggage that he
entrusts to the Service complies with the provisions of these Conditions
of Service and, specifically, Lightravel is not responsible in the case
of entrustment of Luggage containing goods for which the Service is not
permitted.</p>
<p>1.2. The use of the Service is never permitted in the case of Luggage
containing:</p>
<p>- firearms, bombs, armaments, ammunition and their parts;</p>
<p>- explosives, fireworks and other articles of an incendiary or
flammable nature;</p>
<p>- military items;</p>
<p>- Live plants and/or animals, including insects and pets;</p>
<p>- human corpses, organs or body parts, human or animal embryos,
cremated or exhumed human remains;</p>
<p>- animal carcasses, dead or stuffed animals;</p>
<p>- perishable foodstuffs and foodstuffs and beverages requiring
refrigeration or other environmental control;</p>
<p>- money, including but not limited to, cash and equivalents such as
negotiable securities, endorsed shares, bonds and letters of transfer of
funds, coins and collector's stamps;</p>
<p>- gold, jewels, gems, diamonds, watches and precious goods in general
or of particular value;</p>
<p>- Hazardous waste;</p>
<p>- flammable liquids;</p>
<p>- drugs, narcotic substances and psychotropic substances, including
those for recreational or medical use;</p>
<p>- tobacco and tobacco derivatives;</p>
<p>- art objects, antiques, fine textiles and carpets;</p>
<p>- fragile objects, such as glass, bottles, etc.</p>
<p>1.3. Lightravel is never responsible in the event of damage resulting
from the transport of such prohibited goods and reserves the right to
take legal action for compensation for any damage resulting from failure
to comply with the provisions regarding the content of prohibited
Baggage.</p>
<p>1.4. The User guarantees that the Baggage and its contents are its
own property and its possession does not derive from theft,
misappropriation or violent theft, relieving Lightravel from any civil
and/or criminal liability deriving from the false declaration regarding
ownership and availability. of the same.</p>
<p><strong>2. INSPECTION FACULTY</strong></p>
<p>2.1. To verify compliance with the prohibitions referred to in
article 13 above, Lightravel reserves the right to carry out inspections
to verify the content.</p>
<p>2.2. In the case of inspection, the seal and/or any locks may be
removed, unless the User is notified of the inspection and the affixing
of a new seal.</p>
<p>2.3. All inspection procedures will be carried out respecting the
User's confidentiality and video-recorded, to guarantee the contents of
the Baggage, starting from the moment the seal is opened and until the
moment the new seal is re-affixed.</p>
<p>2.4. The video recordings of the inspection will be shown to the User
at the time of collection of the Baggage, if the same expressly requests
it, and in the event that no complaints arise they will be destroyed
within 3 (three) days from the moment of acceptance of the redelivery of
Baggage. In the event of a complaint, these recordings will be used
until the complaint is completely resolved.</p>
<p>2.5. In any case, inspections by the police are permitted, without
any right to intervene on the part of Lightravel, which is expressly
indemnified from any problem and/or damage and/or consequence that may
arise from it.</p>
<p><strong>1. USER OBLIGATIONS</strong></p>
<p>1.1. The User undertakes to:</p>
<p>- do not provide false information and personal details;</p>
<p>- use the Service only for Baggage, and related contents, which are
your own and whose possession does not result from theft and/or
misappropriation or violent theft;</p>
<p>- strictly comply with the conditions and terms of these Terms of
Service;</p>
<p>- pack the contents of the Baggage correctly in order to be protected
against ordinary transport and storage risks;</p>
<p>- do not rely on Lightravel in the event that the Luggage suffers
minimal damage/wear (e.g. small scratches) in relation to the ordinary
risks of the transport and storage service;</p>
<p>- ensure that the contents of the Baggage do not damage other Baggage
transported and stored by Lightravel;</p>
<p>- comply with the provisions regarding the goods allowed in the
Baggage, referred to in the previous article 14;</p>
<p>- respect the scheduled delivery and collection times.</p>
<p><strong>2. STOCK DUE TO FAILURE TO COLLECT</strong></p>
<p>2.1. In the event that the Baggage entrusted by the User is not
collected on the chosen day, Lightravel will mark the Baggage as "in
stock".</p>
<p>2.2. When the Baggage goes into storage, the return times for it are
not guaranteed, with express derogation from the provisions of article
13 - Collection, which the User accepts.</p>
<p>2.3. For each Baggage in "storage", Lightravel will be authorized to
charge the User on a daily basis a storage cost in addition to the cost
paid for the Service and equal to a penalty of €15.00 (fifteen/00) for a
small and large suitcase , €25.00 (twenty-five/00) for an OoF suitcase
for each day or fraction of a day of delay.</p>
<p>2.4. The maximum storage allowed is 7 (seven) days.</p>
<p>2.5. After 7 (seven) days the Luggage will be transferred to a
different warehouse and collection can only take place by prior
appointment with Lightravel (to be fixed by sending an email to
<a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="91f2fee2e5e4fcf4e3e2f4e3e7f8f2f4d1f3f0f6f6e8e2f8e5e5f4e3bff2fefc">[email&#160;protected]</a>) in order to agree on the day and time
of collection, subject to the application of storage costs and penalties
referred to in art. 17.3.</p>
<p>2.6. After 7 (seven) days, invoicing will no longer take place per
day, but rather per week, with advance payment on the first day of the
new week, with the storage costs indicated in the previous points.</p>
<p>2.7. If the User does not pay the increases due to the storage
period, Lightravel will not be able to return the entrusted Baggage,
continuing to charge the storage costs.</p>
<p>2.8. If a month has passed without the User having collected the
Baggage, Lightravel is authorized to dispose of it and its contents,
without the possibility for the User to recover it and/or make any claim
on the Baggage and its contents.</p>
<p><strong>3. RESPONSIBILITY FOR DAMAGES ARISING FROM USERS</strong></p>
<p>3.1. If the User causes damage to Lightravel and/or other users
resulting from their behavior, or from the Baggage and/or its contents,
Lightravel will avail itself of the right to take action against the
User for compensation for any direct damage and indirect.</p>
<p><strong>4. RESPONSIBILITY FOR DAMAGES ARISING FROM THE
SERVICE</strong></p>
<p>4.1. Except in the case of willful misconduct and gross negligence,
Lightravel assumes no responsibility for damage to people, animals
and/or things resulting from the use of the Service.</p>
<p>4.2. By "damage" we expressly mean any obvious alteration to the
Baggage such as to totally invalidate its usefulness and/or
functionality (for a purely indicative and non-exhaustive purpose,
damage to a wheel such that the Baggage can no longer be towed, etc. .),
or any damage directly attributable to clumsy and careless handling of
the Baggage.</p>
<p>4.3. In the event of damage and/or damage to the Baggage correctly
delivered to Lightravel (where correctly delivered specifically means
that the Baggage and its contents comply with the terms and conditions
of use set out therein), the compensation will in any case be limited to
damages directly attributable to Lightravel and, in any case, for the
maximum amount of €300.00 (three hundred/00).</p>
<p><strong>1. OPENING OF CLAIMS AND COMPENSATION OF DAMAGES</strong></p>
<p>1.1. Upon returning the Baggage to the User, the User is required to
verify the integrity of the Baggage and the seal affixed, immediately
reporting any irregularities and/or discrepancies.</p>
<p>1.2. In the event that the User finds any irregularity, he can report
it by communicating it to the warehouse attendant, specifically
indicating the problem encountered. If the faults and/or defects are not
immediately identifiable, or can only be verified following the opening
of the Baggage, the user is required to report this problem within a
maximum of 7 (seven) working days, attaching all the supporting
evidence. useful to correctly identify the type of defect and its
causes, by sending an email to <a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="b9dad6cacdccd4dccbcadccbcfd0dadcf9dbd8dedec0cad0cdcddccb97dad6d4">[email&#160;protected]</a>.</p>
<p>1.3. Following the report, a complaint procedure will be opened with
the assignment of a ticket number to which to refer for carrying out the
complaint procedure.</p>
<p>1.4. The Lightravel complaints service staff will verify all the
elements provided and, in the event that they find the actual existence
of a defect and/or a discrepancy and/or damage to the Baggage, they will
contact the user to start the compensation procedures of the damage.</p>
<p>1.5. If the elements put forward in support of the request are
insufficient to prove the existence and responsibility of the damage
and/or defect complained of, and if the correct documentary integration
is not provided, the procedure will be irrevocably archived.</p>
<p><strong>2. MANDATORY INSURANCE</strong></p>
<p>2.1. The cost of insurance against damages and/or accidents is
included in the cost of the Service offered by Lightravel, as an
additional service and to guarantee the User.</p>
<p><strong>3. RIGHT OF WITHDRAWAL AND CANCELLATION OF THE
RESERVATION</strong></p>
<p>3.1. The User acknowledges that the Service booked and purchased via
the Site or Application consists of free time activities with a specific
date or period of execution, therefore, pursuant to Article 59 of the
Consumer Code, the right of withdrawal is excluded ( so-called right of
repentance) from the Services booked.</p>
<p>3.2. Without prejudice to the provisions of the previous point 23.1,
the User who no longer intends to use the Service once the booking has
been made, may withdraw from the Service free of charge by canceling it
without any cost until midnight of the day before the day scheduled for
delivery, by contacting Customer Service
<a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="95f6fae6e1e0f8f0e7e6f0e7e3fcf6f0d5f7f4f2f2ece6fce1e1f0e7bbf6faf8">[email&#160;protected]</a>.</p>
<p>3.3. If the User cancels the reservation on the day scheduled for
delivery or does not show up at the booked place and time, the entire
cost of the agreed and accepted Service will be charged.</p>
<p><strong>4. ASSISTANCE</strong></p>
<p>4.1. For any problems relating to the Service, the User will be
required to contact customer service at the appropriate email address
<a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="33505c4047465e5641405641455a505673515254544a405a474756411d505c5e">[email&#160;protected]</a></p>
<p><strong>5. INDEMNIFICATION</strong></p>
<p>5.1. The User undertakes to indemnify Lightravel (as well as any
companies controlled or affiliated by the same, its representatives,
directors, agents, licensors, partners and employees) from any
obligation or liability, including any legal costs incurred to defend
themselves in court , which may arise due to damage caused to other
users or third parties in relation to the violation of the terms of the
law or the terms of these Terms of Service.</p>
<p><strong>1. DISCLAIMER OF LIABILITY</strong></p>
<p>1.1. Lightravel will never be responsible for loss of flights or
trains, goodwill, earnings, profit, market, reputation, customers, use,
opportunities, or in any case for any indirect, accidental, special or
consequential circumstances, including, without limitation, in cases of
termination of contract, negligence, willful misconduct or default.</p>
<p>1.2. Lightravel will never be responsible for failure to fulfill its
obligations in the event of unforeseeable and avoidable circumstances,
such as (by way of example but not limited to): natural events including
earthquakes, cyclones, storms, floods, fires, diseases, snow or frost;
force majeure events including (with a merely illustrative and
non-exhaustive list) wars, accidents, terrorist acts, strikes,
embargoes, local disputes or popular uprisings; national or local
disruptions in land transportation networks and mechanical problems with
modes of transportation or machinery; latent or intrinsic defects in the
contents of the shipment; third party criminal acts such as theft,
robbery and arson; acts or omissions attributable to the user or third
parties; acts or omissions attributable to a public official; contents
of the shipment consisting of items that are prohibited goods under the
law or this contract, even if Lightravel had accepted the transport
and/or storage in error.</p>
<p><strong>2. USE LICENSE</strong></p>
<p>2.1. The use of material from the website and application for
commercial purposes without obtaining express written authorization from
Lightravel is expressly prohibited.</p>
<p>2.2. The website may not be used and no part of it may be reproduced
or stored in any other website nor may it be inserted into any system or
service, public or private, for the electronic retrieval of information
without the prior express written authorization of Lightravel.</p>
<p>2.3. Rights not explicitly granted in this website and application
are reserved.</p>
<p>2.4. Users, however, cannot:</p>
<p>- copy, store, modify, change, prepare derivative works of, or alter
in any way any of the content/services provided by Lightravel;</p>
<p>- use any robot, spider, site search/retrieval application, or any
other automatic device, process or means to access, retrieve, scrape or
index any portion of the Site and/Application or their contents;</p>
<p>- defame, offend, harass, engage in threatening practices, threaten
or otherwise violate the rights (such as the right to privacy and
publicity) of others;</p>
<p>- disseminate or publish illegal, obscene, illegitimate, defamatory
or inappropriate content.</p>
<p><strong>3. COMMUNICATIONS</strong></p>
<p>3.1. All communications relating to Lightravel should be sent using
the contact information provided.</p>
<p>3.2. The User accepts that communication with Lightravel occurs
primarily electronically.</p>
<p>3.3. The User is contacted via notifications (available in the
application), e-mail, text message or information is provided by
publishing warning posts on the website. For contractual purposes, the
User accepts these electronic methods of communication and acknowledges
that all contracts, notices, information and other communications
provided electronically by Lightravel comply with the legal requirement
of being in writing.</p>
<p><strong>1. COPYRIGHT</strong></p>
<p>1.1. All trademarks owned by Lightravel, figurative or nominative,
and all other signs, trade names, service marks, word marks, de facto
marks, trade names, illustrations, images, logos appearing relating to
Lightravel are and remain the exclusive property of Lightravel or its
licensors and are protected by applicable trademark laws and relevant
international treaties.</p>
<p>1.2. The Lightravel services, which include any necessary software
used in connection therewith, contain proprietary and confidential
information, protected by applicable intellectual and/or industrial
property and other laws.</p>
<p>1.3. The contents included in the advertisements or the information
presented to the User through the Lightravel services may be protected
by laws regarding copyright, trademarks, service marks, patents or other
intellectual and/or industrial property rights.</p>
<p>1.4. Except as expressly permitted by applicable law or as expressly
authorized by Lightravel or its licensor (for example, an advertiser),
you agree not to use, distribute, reproduce, adapt, publish, translate,
create derivative works of, rent, view, publicly perform or publicly
display the Lightravel Services and User Content or Software.</p>
<p><strong>2. PRIVACY AND COOKIES</strong></p>
<p>2.1. The processing of personal data acquired by Lightravel and the
management of cookies are respectively regulated in the privacy policy
and cookie policy available on the website www.baggysitter.com.</p>
<p>2.2. In any case, pursuant to art. 13 of EU Regulation no. 679/2016,
we inform you that the processing of data will be based on the
principles of correctness, lawfulness and transparency and in
particular:</p>
<p>- the processing to which the data will be subjected will be carried
out by Lightravel, as data controller, pursuant to current legislation
exclusively for the purposes related to the execution of this
contract;</p>
<p>- the data will not be disclosed or transferred to third parties and,
subject to the User's consent, may be processed by Lightravel also for
the sending, in paper or electronic form, of information material of a
commercial nature;</p>
<p>- each party declares and guarantees that its procedures and
activities carried out pursuant to this contract will comply with the
applicable rules on the protection of personal data and application of
the relevant security measures, exempting the other party from any
liability in this regard.</p>
<p><strong>3. CHANGES TO THESE TERMS AND CONDITIONS</strong></p>
<p>3.1. Lightravel reserves the right to make changes to these Terms of
Service at any time in accordance with the provisions of the previous
article 1.4, giving notice to the User through its publication on the
Site and the application.</p>
<p>3.2. The changes to these Conditions of Service will be applied
ratione temporis to individual Users, the facts occurring and the legal
relationships concluded before the entry into force of the new
conditions will be entirely regulated by the previous version, always
available upon request.</p>
<p>3.3. However, it is recommended that you print a copy of the Terms of
Service for future reference.</p>
<p>3.4. The Italian language version of the Conditions of Service
(present and subsequently modified) represents the only version
contractually binding for the parties.</p>
<p>3.5. The User who continues to use the Site and the Application, as
well as the Service offered by Lightravel after the publication of the
changes, must accept the new conditions without reservation.</p>
<p><strong>1. STORAGE OF THE TERMS OF SERVICE</strong></p>
<p>1.1. A copy of these Terms of Service will be archived on the site,
for free consultation by the User. The User can also download the
Conditions of Service from the Site at the following link
www.baggysitter.com.</p>
<p><strong>2. NULLITY</strong></p>
<p>2.1. In the event that any provision of these Terms of Service is
invalid, becomes invalid, or becomes non-binding, you will continue to
comply with all other provisions set forth herein. In this case
Lightravel will replace the aforementioned clause with a clause that is
valid and binding, which has an effect as similar as possible to the
replaced one and which the user undertakes to accept in compliance with
the contents and purposes of this regulation.</p>
<p><strong>3. APPLICABLE LAW AND JURISDICTION</strong></p>
<p>3.1. This contract is regulated by the Italian law.</p>
<p>3.2. The parties expressly establish that the jurisdiction to decide
in the event of a dispute arising is deferred exclusively to the Court
of the place where the consumer resides or has elected domicile, if
located in Italian territory. In all other cases the competent court is
that of the Court of Milan.</p>
<p>Pursuant to and for the purposes of art. 1341 c.c. the User declares
to specifically approve in writing the following clauses: 11.2.
Compliance of the baggage and its condition; 16 Stock due to failure to
collect; 18. Liability for damages arising from the service; 21. Right
of withdrawal and cancellation of reservation; 26 Exclusion from
liability; 34.2. Applicable Law and Competent Court</p>



  </div>
</template>

<script>

export default {
  name: 'CondizioniServizioView',
  methods: {
    goToHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.about {
  text-align: center;
  margin-top: 50px;
}


</style>