<template>
	<v-col cols="12" align="left">
      <h1 style="line-height: 0.8;">{{ $t('message.home.title1') }}</h1>
    </v-col>
    <v-col cols="12" align="left">
      <h1 style="line-height: 0.8;">{{ $t('message.home.title2a') }} <span style="color:#9fcb78">{{ $t('message.home.title2b') }}</span>, {{ $t('message.home.title2c') }}</h1>
    </v-col>
    <v-col cols="12" align="left">
      <h1 style="line-height: 0.8;">{{ $t('message.home.title3') }}</h1>
    </v-col>
    <v-col cols="12" align="left">
      <h1 style="line-height: 0.8;"><span style="color:#9fcb78">{{ $t('message.home.title4') }}</span>!</h1>
    </v-col>
    &nbsp; 
    <v-col cols="12">
      <p><span style="color:#9fcb78; font-weight:bold;">{{ $t('message.home.paragraph1a') }}</span> <strong>{{ $t('message.home.paragraph1b') }}</strong></p>
    </v-col>
    <v-col cols="12">
      <p><span style="color:#9fcb78; font-weight:bold;">{{ $t('message.home.paragraph2a') }}</span> <strong> {{ $t('message.home.paragraph2b') }}</strong></p>
    </v-col>
    <v-col cols="12">
      <p><strong>{{ $t('message.home.paragraph3') }}</strong></p>
    </v-col>
    <v-col>
      <v-btn @click="goto('book')" class="baggy-button" text color="#1c4c34">
        {{ $t('message.home.buttonBook') }}
      </v-btn>
    </v-col>
</template>


<script>
  export default {
  name: 'IntroSection',
  methods: {
    goto(refName) {
        var element = document.getElementById(refName).offsetTop-80;
        window.scrollTo(0, element);
    }
  }
}

</script>

<style>

.baggy-pages {
  color:#1c4c34; 
  margin:0px;
}

.baggy-button {
    color: #1c4c34;
    width:100%;
}

@media screen and (min-width: 480px) {
  h1 {
    font-size:50px;
  }
}

@media screen and (min-width: 600px) {
  h1 {
    font-size:50px;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size:20px;
  }
}

</style>