<template>
  <v-container class="baggy-pages">
    <section class="v-container v-container--fluid v-locale--is-ltr pa-4 pa-sm-6 pa-md-8">
      <v-row>
          <strong><h3>{{ $t('message.contactUs.title') }}</h3></strong>
      </v-row>
           &nbsp;
      <v-row >
            <strong>infocontact@baggysitter.com</strong>
      </v-row>
           &nbsp;
      <v-row >
          <strong>whatsapp: <span style="color:#9fcb78;">+39 351 011 7209</span></strong>
      </v-row>
    </section>
  </v-container>
</template>

<script>

export default {
  name: 'ContactQuestionsView',
  methods: {
    goToHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
  .baggy-pages {
    color:#1c4c34; 
    margin-top:70px;
  }


</style>