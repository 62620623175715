import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'

// Internationalization
import { createI18n } from 'vue-i18n'
import en from './i18n/en.json';
import it from './i18n/it.json';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import LuxonAdapter from "@date-io/luxon"
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'

import 'maz-ui/styles'

const icons = {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  }

const vuetify = createVuetify({
    icons,
    components,
    directives,
    date: {
        adapter: LuxonAdapter,
        locale: {
            it: 'it-IT',
        }
    },
})

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    it
  }
})

const app = createApp(App)

app.use(VueAxios, axios)
app.use(vuetify)
app.use(router)
app.use(i18n)

app.mount('#app')